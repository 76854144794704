@import '../../../../scss/theme-bootstrap';

.mpp-header-content-block {
  .content-block {
    &__inner {
      .content-block--content {
        .content-block__content-text {
          &.padding--bare {
            @if $cr24_plp == true {
              padding: 1.7rem;
              /* stylelint-disable-next-line max-nesting-depth */
              @include breakpoint($medium-up) {
                padding: 4.75rem;
              }
            } @else {
              padding: 3.25rem 1.25rem 3.75rem;
            }
          }
          .content-block--text {
            height: auto;
            .content-block__line {
              .custom-text {
                p {
                  line-height: inherit;
                }
              }
            }
          }
          .mobile-hidden {
            .content-block--text {
              width: 22%;
              .content-block__line {
                margin: 1rem auto 0;
                p {
                  font-size: 15px;
                  line-height: 1.33;
                }
              }
            }
          }
        }
      }
    }
    &__responsive-text {
      padding: 0;
    }
    &__content-buttons {
      margin: 0;
      a {
        margin: 15px 15px 0;
      }
    }
    .position-image,
    .position--bare {
      @include contentBlockImageHeight;
    }
    .position-text {
      @include contentBlockTextHeight;
      .content-block__picture {
        @if $cr24_plp == true {
          max-height: 110px;
          overflow: hidden;
          @include breakpoint($medium-up) {
            max-height: 204px;
          }
        }
        img,
        video {
          height: 100%;
        }
        img {
          @if $cr24_plp == true {
            filter: brightness(60%);
          }
        }
      }
    }
  }
}
.content-block__breadcrumbs {
  nav.breadcrumbs {
    @if $cr24_plp == true {
      padding: 20px;
      @include breakpoint($medium-up) {
        padding: 20px 40px;
      }
    }
    background: none;
    .breadcrumbs__level {
      @include typography-annotation-text;
      /* stylelint-disable-next-line selector-max-type */
      a {
        @include typography-annotation-text;
        text-transform: uppercase;
      }
      /* stylelint-disable-next-line selector-max-type */
      &:nth-last-child(2) a {
        color: $color-gray-on-light;
        pointer-events: none;
      }
      .color-black & {
        a,
        &:before {
          color: $color-black;
        }
      }
      .color-core-black & {
        a,
        &:before {
          color: $color-core-black;
        }
      }
      .color-gray-light & {
        a,
        &:before {
          color: $color-gray-on-light;
        }
      }
      .color-white & {
        a,
        &:before {
          color: $color-white;
        }
      }
      .color-pink & {
        a,
        &:before {
          color: $color-pink;
        }
      }
      &:before {
        content: ' •';
      }
      &:first-child:before {
        content: '';
      }
    }
  }
  .breadcrumb__level--count {
    display: none;
  }
}
